import { defineStore } from 'pinia';
import { useCardlistStore } from './cardlistStore';

export const useGlobalStore = defineStore('globalStore', {
  state: () => ({
    noScroll: false,
    fixedClasses: ['.deck-frame-container'],
    toasts: [],
    showLogin: false,
    previousRoute: null,
    userData: {
      collection: null, // Add this new property
    },
    userStapleCards: [],
    userStaples: [], // Initialize as an array
    isLoading: false,
    loadingMessage: '',
    userCheck: null, // New variable to track user check status
    gameConfig: null,
    ignoredEvents: [], // Add this new state for ignored events
    mailboxData: {
      event: [],
      notification: [],
      receivedItem: []
    },
    newContentAvailable: false,
    lastDataFetch: null,
    contentData: null,
    displayOwnedCardsCount: localStorage.getItem('displayOwnedCardsCount') !== 'false', // true by default
    displayBookmarks: localStorage.getItem('displayBookmarks') !== 'false', // true by default
    showNewCardsFirst: localStorage.getItem('showNewCardsFirst') !== 'false', // true by default
    isPricingVisible: localStorage.getItem('isPricingVisible') !== 'false', // true by default
  }),
  getters: {
    isExUser() {
      const roles = this.userData?.roles || '';
      const roleArray = typeof roles === 'string' ? roles.split(',') : roles;
      return roleArray.includes('Administrator') || roleArray.includes('EX');
    }
  },
  actions: {
    setNoScroll(value) {
      this.noScroll = value;
    },
    applyNoScroll() {
      try {
        const containers = document.querySelectorAll('.deck-frame-container');
        containers.forEach(container => {
          container.scrollTop = 0;
        });

        this.fixedClasses.forEach(className => {
          const elements = document.querySelectorAll(className);
          elements.forEach(element => {
            if (this.noScroll) {
              element.classList.add('no-scroll');
            } else {
              element.classList.remove('no-scroll');
            }
          });
        });
      } catch (error) {
        this.addToast({
          message: `Error applying no scroll: ${error.message}`,
          type: 'error',
        });
      }
    },
    removeNoScroll() {
      try {
        const noScrollElements = document.querySelectorAll('.no-scroll');
        noScrollElements.forEach(element => {
          element.classList.remove('no-scroll');
        });
      } catch (error) {
        this.addToast({
          message: `Error removing no scroll: ${error.message}`,
          type: 'error',
        });
      }
    },
    addToast(toast) {
      this.toasts.push(toast);
      setTimeout(() => {
        this.removeToast(toast);
      }, 4000);
    },
    removeToast(toast) {
      const index = this.toasts.indexOf(toast);
      if (index !== -1) {
        this.toasts.splice(index, 1);
      }
    },
    setShowLogin(value) {
      this.showLogin = value;
    },
    setPreviousRoute(route) {
      this.previousRoute = route;
    },
    async updateUserData(data) {
      this.userData = {
        ...data,
        collection: data.collection || null, // Ensure collection is properly set
      };
      //console.log(this.userData);
      this.userCheck = true;
      this.userStaples = typeof data.userStapleCards === 'string' ? data.userStapleCards.split(';').map(s => s.trim()) : [];
    },
    clearUserData() {
      this.userData = {
        collection: null, // Ensure collection is cleared
      };
      this.userCheck = false;
      this.userStaples = [];
      localStorage.removeItem('syncToken');
      localStorage.removeItem('mailboxAndContentData');
      localStorage.removeItem('lastDataFetch');
      localStorage.removeItem('lastContentCheck');
      localStorage.removeItem('newContentAvailable');
      
      // Clear store data
      this.mailboxData = {
        event: [],
        notification: [],
        receivedItem: []
      };
      this.contentData = null;
      this.lastDataFetch = null;
      this.newContentAvailable = false;
    },
    updateOwnedBanners(newBanners) {
      this.userData.ownedBanners = newBanners;
    },
    updateRoles(newRoles) {
      this.userData.roles = newRoles;
    },
    updateSyncToken(newToken) {
      this.userData.sync_token = newToken;
      localStorage.setItem('syncToken', newToken); // Save token locally
    },
    addStaple(staple) {
      if (Object.keys(this.userData).length === 0) {
        this.addToast({
          message: 'You must be logged in to mark staple.',
          type: 'error',
        });
        return;
      }

      if (this.userStaples == null) this.userStaples = [];
      if (!this.userStaples.includes(staple)) {
        this.userStaples.push(staple);
      }
      
      try {
        // Call backend to add staple
        const apiUrl = `${this.gameConfig.startUrl}/${this.gameConfig.gameid}/external/decklist-functions.php`;
        const requestBody = new URLSearchParams({
          action: 'setStapple', // Corrected action name
          cardId: staple,
          remove: false,
        });

        fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: requestBody,
        })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            this.addToast({
              message: 'Staple added successfully!',
              type: 'success',
            });
          } else {
            this.userStaples = this.userStaples.filter(s => s !== staple); // Revert the change
            this.addToast({
              message: result.message,
              type: 'error',
            });
          }
        })
        .catch(error => {
          this.userStaples = this.userStaples.filter(s => s !== staple); // Revert the change
          this.addToast({
            message: `Failed to save staple: ${error.message}`,
            type: 'error',
          });
        });
      } catch (error) {
        this.addToast({
          message: `Unexpected error: ${error.message}`,
          type: 'error',
        });
      }
    },
    removeStaple(staple) {
      if (Object.keys(this.userData).length === 0) {
        this.addToast({
          message: 'You must be logged in to mark staple.',
          type: 'error',
        });
        return;
      }

      if (this.userStaples == null) this.userStaples = [];
      this.userStaples = this.userStaples.filter(s => s !== staple);
      
      try {
        // Call backend to remove staple
        const apiUrl = `${this.gameConfig.startUrl}/${this.gameConfig.gameid}/external/decklist-functions.php`;
        const requestBody = new URLSearchParams({
          action: 'setStapple', // Corrected action name
          cardId: staple,
          remove: true,
        });

        fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: requestBody,
        })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            this.addToast({
              message: 'Staple removed successfully!',
              type: 'success',
            });
          } else {
            this.userStaples.push(staple); // Revert the change
            this.addToast({
              message: result.message,
              type: 'error',
            });
          }
        })
        .catch(error => {
          this.userStaples.push(staple); // Revert the change
          this.addToast({
            message: `Failed to remove staple: ${error.message}`,
            type: 'error',
          });
        });
      } catch (error) {
        this.addToast({
          message: `Unexpected error: ${error.message}`,
          type: 'error',
        });
      }
    },
    async claimBanner(item) {
      const globalStore = useGlobalStore();
      const url = `${this.gameConfig.startUrl}/${this.gameConfig.gameid}/external/manage_user_mails.php`;
      const data = {
        action: 'claimBanner',
        boxitem: item
      };
    
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        });
    
        const result = await response.json();
        if (result.status === 'OK' || result.status === 'ERROR') { // Handle both OK and ERROR status
          // Show success/info message
          globalStore.addToast({
            message: result.message,
            type: result.status === 'OK' ? 'success' : 'info'
          });
    
          if (result.status === 'OK') {
            // Create a new array of owned banners
            const currentBanners = this.userData.ownedBanners ? this.userData.ownedBanners.split(',') : [];
            if (!currentBanners.includes(item.banner)) {
              currentBanners.push(item.banner);
              // Update the store immediately
              this.userData = {
                ...this.userData,
                ownedBanners: currentBanners.join(',')
              };
            }
          }
        }
      } catch (error) {
        console.error('Error claiming banner', error);
        globalStore.addToast({
          message: `Error claiming banner: ${error.message}`,
          type: 'error',
        });
      }
    },
    async claimRole(item) {
      const globalStore = useGlobalStore();
      const url = `${this.gameConfig.startUrl}/${this.gameConfig.gameid}/external/manage_user_mails.php`;
      const data = {
        action: 'claimRole',
        boxitem: item
      };
    
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        });
    
        const result = await response.json();
        if (result.status === 'OK' || result.status === 'ERROR') {
          // Always show message from backend
          globalStore.addToast({
            message: result.message,
            type: result.status === 'OK' ? 'success' : 'info'
          });
    
          if (result.status === 'OK') {
            // Create a new array of roles
            const currentRoles = this.userData.roles ? this.userData.roles.split(',').filter(r => r.trim()) : [];
            const newRole = item.role.trim();
            
            if (!currentRoles.includes(newRole)) {
              currentRoles.push(newRole);
              // Update the store immediately
              this.userData = {
                ...this.userData,
                roles: currentRoles.join(',')
              };
            }
          }
          // Return true even if role already exists to mark item as claimed
          return true;
        }
        return false;
      } catch (error) {
        console.error('Error claiming role', error);
        globalStore.addToast({
          message: `Error claiming role: ${error.message}`,
          type: 'error',
        });
        return false;
      }
    },
    trackEvent(eventName, params) {
      if (window.gtag) {
        window.gtag('event', eventName, {
          "event_category": "header-buttons",
          "event_label": params["event-label"],
          "value": params.value
        });
      }
    },
    setLoading({ isLoading, loadingMessage }) {
      this.isLoading = isLoading;
      this.loadingMessage = loadingMessage;
    },
    clearLoading() {
      this.isLoading = false;
      this.loadingMessage = '';
    },
    ignoreEvent(eventId) {
      if (!this.ignoredEvents.includes(eventId)) {
        this.ignoredEvents.push(eventId);
        // Save to localStorage
        localStorage.setItem('ignoredEvents', JSON.stringify(this.ignoredEvents));
      }
    },

    loadIgnoredEvents() {
      const saved = localStorage.getItem('ignoredEvents');
      if (saved) {
        this.ignoredEvents = JSON.parse(saved);
      }
    },

    clearIgnoredEvents() {
      this.ignoredEvents = [];
      localStorage.removeItem('ignoredEvents');
    },

    async fetchMailboxAndContent(force = false) {
      const today = new Date().toDateString();
      const storedDate = localStorage.getItem('lastDataFetch');
      const storedData = localStorage.getItem('mailboxAndContentData');
      const lastContentCheck = localStorage.getItem('lastContentCheck');

      // First try to use cached data if available and not forced refresh
      if (!force && storedData && storedDate === today) {
        try {
          const cached = JSON.parse(storedData);
          
          // Update store with cached data
          this.mailboxData = cached.mailboxData || {
            event: [],
            notification: [],
            receivedItem: []
          };
          this.contentData = cached.contentData;
          this.lastDataFetch = storedDate;

          // Check for new content using cached data
          if (this.contentData?.length > 0) {
            const latestContentDate = new Date(Math.max(
              ...this.contentData.map(item => new Date(item.created_at))
            ));
            this.newContentAvailable = !lastContentCheck || latestContentDate > new Date(lastContentCheck);
          }
          
          //console.log('Using cached data from today');
          return; // Exit early if we successfully used cached data
        } catch (error) {
          console.error('Error parsing cached data:', error);
        }
      }

      // Only fetch fresh data if needed
      if (force || !storedData || storedDate !== today) {
        try {
          this.setLoading({ isLoading: true, loadingMessage: 'Fetching updates...' });
          
          const baseUrl = 'https://exburst.dev';
          const fetchUrl = `${baseUrl}/fetch_content.php`;
          
          const params = new URLSearchParams({
            action: 'initialFetch',
            gameid: this.gameConfig.gameid,
            page: 1,
            limit: 6
          });

          const response = await fetch(`${fetchUrl}?${params.toString()}`);
          const data = await response.json();

          // Store the fresh data
          localStorage.setItem('mailboxAndContentData', JSON.stringify({
            mailboxData: data.mailboxData,
            contentData: data.currentPageContent
          }));
          localStorage.setItem('lastDataFetch', today);

          // Update store state
          this.mailboxData = data.mailboxData || {
            event: [],
            notification: [],
            receivedItem: []
          };
          this.contentData = data.currentPageContent;
          this.lastDataFetch = today;

          // Check for new content
          if (this.contentData?.length > 0) {
            const latestContentDate = new Date(Math.max(
              ...this.contentData.map(item => new Date(item.created_at))
            ));
            this.newContentAvailable = !lastContentCheck || latestContentDate > new Date(lastContentCheck);
            localStorage.setItem('newContentAvailable', this.newContentAvailable);
          }

          //console.log('Fetched fresh data');
        } catch (error) {
          console.error('Error fetching data:', error);
          this.addToast({
            message: `Error fetching updates: ${error.message}`,
            type: 'error',
          });
        } finally {
          this.setLoading({ isLoading: false, loadingMessage: '' });
        }
      }
    },

    clearMailboxAndContentCache() {
      localStorage.removeItem('mailboxAndContentData');
      localStorage.removeItem('lastDataFetch');
      localStorage.removeItem('lastContentCheck');
      localStorage.removeItem('newContentAvailable');
      this.mailboxData = null;
      this.contentData = null;
      this.lastDataFetch = null;
      this.newContentAvailable = false;
    },
    toggleDisplayOwnedCardsCount() {
      this.displayOwnedCardsCount = !this.displayOwnedCardsCount;
      localStorage.setItem('displayOwnedCardsCount', this.displayOwnedCardsCount);
    },
    toggleDisplayBookmarks() {
      this.displayBookmarks = !this.displayBookmarks;
      localStorage.setItem('displayBookmarks', this.displayBookmarks);
    },
    toggleShowNewCardsFirst() {
      this.showNewCardsFirst = !this.showNewCardsFirst;
      localStorage.setItem('showNewCardsFirst', this.showNewCardsFirst);
      // Trigger reordering in cardlist
      const cardlistStore = useCardlistStore();
      cardlistStore.applyFilters();
    },
    toggleIsPricingVisible() {
      this.isPricingVisible = !this.isPricingVisible;
      localStorage.setItem('isPricingVisible', this.isPricingVisible);
    },
  }
});
