import { defineStore } from 'pinia';
import { useGlobalStore } from './globalStore';
import { useCardlistStore } from './cardlistStore';

const CARD_PROPS_MAP = {
  isTrading: 't',
  isIgnored: 'i',
  isWanted: 'w',
  count: 'c'
};

const CARD_PROPS_REVERSE_MAP = Object.fromEntries(
  Object.entries(CARD_PROPS_MAP).map(([key, value]) => [value, key])
);

// Helper functions for consistent value handling
const toBooleanValue = (value) => {
  if (value === 1) return true;
  if (value === 0) return false;
  return Boolean(value);
};

const toStorageValue = (value) => {
  if (value === true || value === 1) return 1;
  if (value === false || value === 0) return 0;
  return value;
};

export const useCollectionStore = defineStore('collectionStore', {
  state: () => ({
    collections: {
      default: {
        collectionName: 'Default Collection',
        content: {}
      }
    },
    hasChanges: false,
    activeCollection: 'default',
    hideCollected: false,
    showOnlyCollected: false,
    showOnlyTrading: false,
    hideIgnored: false,
    showOnlyWanted: false,
    seriesStats: {},
    remoteUsername: null,  // Add this line
    isStatsCalculated: false,  // Add this line
    tags: new Set(), // Store unique tags
    selectedTags: [], // Add this line to track selected tags for filtering
    tagEditorVisible: false, // Add this line to track tag editor visibility
    selectedCardForTagging: null, // Track the card being tagged
    allTags: new Set(), // Add this to store all unique tags
  }),

  actions: {
    convertCardToCompressedFormat(card) {
      const compressed = {
        n: card.cardNo,  // Always include cardNo as 'n'
        c: parseInt(card.count || card.c || 0),
        t: toStorageValue(card.isTrading || card.t),
        i: toStorageValue(card.isIgnored || card.i),
        w: toStorageValue(card.isWanted || card.w),
        tag: card.tag || undefined
      };
      return compressed;
    },

    convertCardFromCompressedFormat(card) {
      return {
        cardNo: card.n,
        count: card.c || 0,
        isTrading: card.t || false,
        isIgnored: card.i || false,
        isWanted: card.w || false
      };
    },

    async initializeCollection(userId) {
      const globalStore = useGlobalStore();
      
      if (userId) {
        try {
          const response = await fetch(`${globalStore.gameConfig.startUrl}/${globalStore.gameConfig.gameid}/external/collection_functions.php?user=${userId}&gameid=${globalStore.gameConfig.gameid}`);
          const result = await response.json();
          
          if (result.success && result.collection) {
            const parsedCollection = JSON.parse(result.collection);
            if (parsedCollection && typeof parsedCollection === 'object') {
              // Check if using old format by looking at a single card
              const sampleCard = Object.values(parsedCollection.default?.content || {})[0];
              const isOldFormat = sampleCard && ('count' in sampleCard || 'isWanted' in sampleCard || 'isTrading' in sampleCard || 'isIgnored' in sampleCard);

              if (isOldFormat) {
                // Convert from old format to new compressed format
                parsedCollection.default.content = Object.fromEntries(
                  Object.entries(parsedCollection.default.content).map(([cardNo, card]) => [
                    cardNo,
                    {
                      n: cardNo,
                      c: parseInt(card.count || 0),
                      t: toStorageValue(card.isTrading),
                      i: toStorageValue(card.isIgnored),
                      w: toStorageValue(card.isWanted)
                    }
                  ])
                );
              }

              this.collections = parsedCollection;
              if (!this.collections.seriesStats) {
                this.collections.seriesStats = {};
              }
              this.calculateCollectionStats();

              // Collect all tags from the collection
              Object.values(parsedCollection.default?.content || {}).forEach(card => {
                if (card.tag) {
                  this.allTags.add(card.tag);
                }
              });
            }
          }
          this.remoteUsername = result.username;
        } catch (error) {
          console.error('Error fetching collection:', error);
          globalStore.addToast({
            message: 'Error loading collection data',
            type: 'error'
          });
        }
      }
    },

    initializeCardInCollection(cardNo) {
      if (!this.collections[this.activeCollection].content[cardNo]) {
        this.collections[this.activeCollection].content[cardNo] = {
          n: cardNo,     // Store cardNo as 'n'
          c: 0,         // count
          t: 0,    // Use 0 instead of false
          i: 0,    // Use 0 instead of false
          w: 0     // Use 0 instead of false
        };
      }
      return this.collections[this.activeCollection].content[cardNo];
    },

    updateCard(cardNo, cardSeries, increment) {
      //console.log('updateCard() called with cardNo:', cardNo, 'increment:', increment);
      // cardSeries parameter is kept for backwards compatibility but not used anymore
      const card = this.initializeCardInCollection(cardNo);
      if (increment && (card.c || 0) < 99 || !increment && (card.c || 0) > 0) {
        card.c = (card.c || 0) + (increment ? 1 : -1);  // Use compressed 'c' property
        this.hasChanges = true;

        // Only call updateSeriesStats if we have gameConfig
        const globalStore = useGlobalStore();
        if (globalStore.gameConfig) {
          this.updateSeriesStats(cardNo);
        }
        this.calculateCollectionStats();
      }
    },

    updateSeriesStats(cardNo) {
      const globalStore = useGlobalStore();
      const cardlistStore = useCardlistStore();
      
      // Find the card in cardlist to get its series
      const card = cardlistStore.cards.find(c => c[globalStore.gameConfig.cardId_column] === cardNo);
      if (!card) {
        console.warn('Card not found:', cardNo);
        return;
      }
      
      const cardSeries = card[globalStore.gameConfig.series_column];
      //console.log('Updating stats for series:', cardSeries);

      // Safety check for gameConfig
      if (!globalStore.gameConfig) {
        console.warn('gameConfig not available yet');
        return;
      }

      const maxPerDeck = globalStore.gameConfig.maximumUniqueCardPerDeck;
      
      // Store original series name as key
      const seriesKey = cardSeries;
      
      // Initialize series stats if needed
      if (!this.collections.seriesStats) {
        this.collections.seriesStats = {};
      }

      // Get all cards from this series
      const seriesCards = cardlistStore.cards.filter(card => {
        const currentCardSeries = card[globalStore.gameConfig.series_column];
        return currentCardSeries === cardSeries;
      });

     //console.log('Found cards for series:', seriesCards.length);

      // Calculate stats for the series
      const stats = {
        totalSeriesCount: seriesCards.length,
        totalCollectedCount: seriesCards.reduce((count, card) => {
          const cardId = card[globalStore.gameConfig.cardId_column];
          const cardData = this.collections[this.activeCollection].content[cardId];
          const isCollected = (cardData?.c || 0) > 0 && !toBooleanValue(cardData?.i);
          return count + (isCollected ? 1 : 0);
        }, 0),
        playsetCount: seriesCards.reduce((count, card) => {
          const cardId = card[globalStore.gameConfig.cardId_column];
          const cardData = this.collections[this.activeCollection].content[cardId];
          const isPlayset = (cardData?.c || 0) >= maxPerDeck && !toBooleanValue(cardData?.i);
          return count + (isPlayset ? 1 : 0);
        }, 0),
        totalTradingCount: seriesCards.reduce((count, card) => {
          const cardId = card[globalStore.gameConfig.cardId_column];
          const cardData = this.collections[this.activeCollection].content[cardId];
          const isTrading = (cardData?.c || 0) > 0 && cardData?.t === 1;
          return count + (isTrading ? 1 : 0);
        }, 0)
      };

      //console.log('Calculated stats for series:', seriesKey, stats);
      this.collections.seriesStats[seriesKey] = stats;
    },

    async saveCollection() {
      const globalStore = useGlobalStore();
      const cardlistStore = useCardlistStore();
      try {
        // Create seriesStats with separate stats for each series
        const seriesStats = {};
        const selectedSeries = cardlistStore.selectedSeries;

        // Calculate stats for each series individually
        selectedSeries.forEach(seriesKey => {
          const normalizedSeries = seriesKey.toLowerCase().endsWith('-') ? 
            seriesKey.toLowerCase().slice(0, -1) : seriesKey.toLowerCase();

          const seriesCards = cardlistStore.cards.filter(card => {
            const cardSeries = card[globalStore.gameConfig.series_column].toLowerCase();
            const normalizedCardSeries = cardSeries.endsWith('-') ? 
              cardSeries.slice(0, -1) : cardSeries;
            return normalizedCardSeries === normalizedSeries;
          });

          seriesStats[normalizedSeries] = {
            totalSeriesCount: seriesCards.length,
            totalCollectedCount: seriesCards.reduce((count, card) => {
              const cardId = card[globalStore.gameConfig.cardId_column];
              const cardData = this.collections[this.activeCollection].content[cardId];
              const isCollected = (cardData?.c || 0) > 0 && !toBooleanValue(cardData?.i);
              return count + (isCollected ? 1 : 0);
            }, 0),
            playsetCount: seriesCards.reduce((count, card) => {
              const cardId = card[globalStore.gameConfig.cardId_column];
              const cardData = this.collections[this.activeCollection].content[cardId];
              const isPlayset = (cardData?.c || 0) >= globalStore.gameConfig.maximumUniqueCardPerDeck && !toBooleanValue(cardData?.i);
              return count + (isPlayset ? 1 : 0);
            }, 0)
          };
        });

        // Prepare collection data for saving
        const compressedCollection = {
          ...this.collections,
          default: {
            ...this.collections.default,
            content: Object.fromEntries(
              Object.entries(this.collections.default.content).map(([cardNo, card]) => [
                cardNo,
                this.convertCardToCompressedFormat(card)
              ])
            )
          }
        };

        // Save with updated series stats
        const response = await fetch(`${globalStore.gameConfig.startUrl}/${globalStore.gameConfig.gameid}/external/collection_functions.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'saveCollection',
            gameid: globalStore.gameConfig.gameid,
            collection: JSON.stringify(compressedCollection),
            seriesStats
          })
        });

        const result = await response.json();
        if (result.success) {
          this.hasChanges = false;
          // Update the collection in globalStore.userData
          globalStore.userData = {
            ...globalStore.userData,
            collection: JSON.stringify(this.collections)  // Fixed: Use this.collections directly
          };
          globalStore.addToast({
            message: result.message,
            type: 'success'
          });
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        globalStore.addToast({
          message: `Error saving collection: ${error.message}`,
          type: 'error'
        });
      }
    },

    toggleHideCollected() {
      //console.log('toggleHideCollected() called');
      this.hideCollected = !this.hideCollected;
      if (this.hideCollected) this.showOnlyCollected = false;
      this.triggerFiltersUpdate();
    },

    toggleShowOnlyCollected() {
      //console.log('toggleShowOnlyCollected() called');
      this.showOnlyCollected = !this.showOnlyCollected;
      if (this.showOnlyCollected) this.hideCollected = false;
      this.triggerFiltersUpdate();
    },

    toggleShowOnlyTrading() {
      //console.log('toggleShowOnlyTrading() called');
      this.showOnlyTrading = !this.showOnlyTrading;
      if (this.showOnlyTrading) {
        this.hideCollected = false;
        this.showOnlyCollected = false;
        // Add tradeList parameter to URL
        const url = new URL(window.location.href);
        url.searchParams.set('tradeList', 'true');
        window.history.replaceState({}, '', url.toString());
      } else {
        // Remove tradeList parameter from URL
        const url = new URL(window.location.href);
        url.searchParams.delete('tradeList');
        window.history.replaceState({}, '', url.toString());
      }
      this.triggerFiltersUpdate();
    },

    toggleCardTrading(cardNo) {
      //console.log('toggleCardTrading() called with cardNo:', cardNo);
      const card = this.initializeCardInCollection(cardNo);
      card.t = toStorageValue(!toBooleanValue(card.t));
      if (toBooleanValue(card.t) && !card.c) {
        card.c = 1;
      }
      this.hasChanges = true;
    },

    toggleHideIgnored() {
      //console.log('toggleHideIgnored() called');
      this.hideIgnored = !this.hideIgnored;
      this.triggerFiltersUpdate();
    },

    toggleCardIgnored(cardNo) {
      //console.log('toggleCardIgnored() called with cardNo:', cardNo);
      const card = this.initializeCardInCollection(cardNo);
      card.i = toStorageValue(!toBooleanValue(card.i));
      this.hasChanges = true;
      
      // Recalculate stats after toggling ignored status
      const globalStore = useGlobalStore();
      if (globalStore.gameConfig) {
        this.updateSeriesStats(cardNo);
      }
      this.calculateCollectionStats();
    },

    toggleCardWanted(cardNo) {
      //console.log('toggleCardWanted() called with cardNo:', cardNo);
      const card = this.initializeCardInCollection(cardNo);
      card.w = toStorageValue(!toBooleanValue(card.w));
      this.hasChanges = true;
    },

    toggleShowOnlyWanted() {
      //console.log('toggleShowOnlyWanted() called');
      this.showOnlyWanted = !this.showOnlyWanted;
      if (this.showOnlyWanted) {
        this.hideCollected = false;
        this.showOnlyCollected = false;
        this.showOnlyTrading = false;
      }
      this.triggerFiltersUpdate();
    },

    triggerFiltersUpdate() {
      //console.log('triggerFiltersUpdate() called');
      const cardlistStore = useCardlistStore();
      cardlistStore.applyFilters();
    },

    setCollectionData(data) {
      //console.log('setCollectionData() called with data:', data);
      if (data.collection) {
        this.collections = JSON.parse(data.collection);
        
        // Initialize seriesStats if needed
        if (!this.collections.seriesStats) {
          this.collections.seriesStats = {};
        }

        // Store the username
        if (data.username) {
          this.remoteUsername = data.username;
        }

        // Recalculate all series stats
        const cardlistStore = useCardlistStore();
        const globalStore = useGlobalStore();
        
        if (globalStore.gameConfig && cardlistStore.cards.length > 0) {
          const maxPerDeck = globalStore.gameConfig.maximumUniqueCardPerDeck;
          
          // Group cards by series
          const seriesGroups = {};
          cardlistStore.cards.forEach(card => {
            const series = card[globalStore.gameConfig.series_column].toLowerCase();
            const normalizedSeries = series.endsWith('-') ? series.slice(0, -1) : series;
            
            if (!seriesGroups[normalizedSeries]) {
              seriesGroups[normalizedSeries] = [];
            }
            seriesGroups[normalizedSeries].push(card);
          });

          // Calculate stats for each series
          Object.entries(seriesGroups).forEach(([normalizedSeries, cards]) => {
            this.collections.seriesStats[normalizedSeries] = {
              totalSeriesCount: cards.length,
              totalCollectedCount: cards.reduce((count, card) => {
                const cardId = card[globalStore.gameConfig.cardId_column];
                const isCollected = this.getCardCount(cardId) > 0 && !this.isCardIgnored(cardId);
                return count + (isCollected ? 1 : 0);
              }, 0),
              playsetCount: cards.reduce((count, card) => {
                const cardId = card[globalStore.gameConfig.cardId_column];
                const isPlayset = this.getCardCount(cardId) >= maxPerDeck && !this.isCardIgnored(cardId);
                return count + (isPlayset ? 1 : 0);
              }, 0)
            };
          });
        }
      }
      this.remoteUsername = data.username;
    },

    calculateCollectionStats() {
      const cardlistStore = useCardlistStore();
      const globalStore = useGlobalStore();
      const maxPerDeck = globalStore.gameConfig.maximumUniqueCardPerDeck;

      // Group cards by original series name
      const seriesGroups = {};
      cardlistStore.cards.forEach(card => {
        const series = card[globalStore.gameConfig.series_column];
        const normalizedSeries = series.toLowerCase().endsWith('-') ? 
          series.toLowerCase().slice(0, -1) : series.toLowerCase();
        
        if (!seriesGroups[normalizedSeries]) {
          seriesGroups[normalizedSeries] = [];
        }
        seriesGroups[normalizedSeries].push(card);
      });

      //console.log('Series groups:', Object.keys(seriesGroups));

      // Calculate stats for each series
      Object.entries(seriesGroups).forEach(([series, cards]) => {
        // Filter out ignored cards before calculations
        const nonIgnoredCards = cards.filter(card => {
          const cardId = card[globalStore.gameConfig.cardId_column];
          const cardData = this.collections[this.activeCollection].content[cardId];
          return !toBooleanValue(cardData?.i);
        });

        const stats = {
          totalSeriesCount: nonIgnoredCards.length,
          totalCollectedCount: nonIgnoredCards.reduce((count, card) => {
            const cardId = card[globalStore.gameConfig.cardId_column];
            const cardData = this.collections[this.activeCollection].content[cardId];
            return count + ((cardData?.c || 0) > 0 ? 1 : 0);
          }, 0),
          playsetCount: nonIgnoredCards.reduce((count, card) => {
            const cardId = card[globalStore.gameConfig.cardId_column];
            const cardData = this.collections[this.activeCollection].content[cardId];
            return count + ((cardData?.c || 0) >= maxPerDeck ? 1 : 0);
          }, 0),
          totalTradingCount: nonIgnoredCards.reduce((count, card) => {
            const cardId = card[globalStore.gameConfig.cardId_column];
            const cardData = this.collections[this.activeCollection].content[cardId];
            return count + ((cardData?.c || 0) > 0 && cardData?.t === 1 ? 1 : 0);
          }, 0)
        };

        // Both store by normalized name and original name for compatibility
        this.collections.seriesStats[series] = stats;
        this.collections.seriesStats[series.toLowerCase()] = stats;
      });
      
      this.isStatsCalculated = true;
    },

    setCardTag(cardId, tag) {
      const card = this.initializeCardInCollection(cardId);
      if (tag && tag.length > 10) return; // Validate tag length
      
      if (tag) {
        this.tags.add(tag);
        this.allTags.add(tag);
        card.tag = tag;
      } else {
        delete card.tag;
      }
      this.hasChanges = true;
    },

    removeCardTag(cardId) {
      const card = this.initializeCardInCollection(cardId);
      delete card.tag;
      this.hasChanges = true;
    },

    toggleTagFilter(tag) {
      const index = this.selectedTags.indexOf(tag);
      if (index === -1) {
        this.selectedTags.push(tag);
      } else {
        this.selectedTags.splice(index, 1);
      }
      this.triggerFiltersUpdate();
    },

    clearTagFilters() {
      this.selectedTags = [];
      this.triggerFiltersUpdate();
    },

    toggleTagEditor() {
      this.tagEditorVisible = !this.tagEditorVisible;
    },

    openTagEditor(cardId) {
      this.selectedCardForTagging = cardId;
      this.tagEditorVisible = true;
    },

    closeTagEditor() {
      this.selectedCardForTagging = null;
      this.tagEditorVisible = false;
    }
  },

  getters: {
    getCardCount: (state) => (cardId) => {
      //console.log('getCardCount() called with cardId:', cardId);
      // Use the correct card ID from the card based on game config
      const cardData = state.collections[state.activeCollection].content[cardId];
      return cardData?.c || 0;
    },

    isCardTrading: (state) => (cardId) => {
      //console.log('isCardTrading() called with cardId:', cardId);
      const card = state.collections[state.activeCollection].content[cardId];
      const cardData = state.collections[state.activeCollection].content[cardId];
      return toBooleanValue(cardData?.t) || toBooleanValue(cardData?.w);
    },

    getStats: (state) => (totalCards, maxPerCard, cardFilter = null) => {
      //console.log('getStats() called with totalCards:', totalCards, 'maxPerCard:', maxPerCard, 'cardFilter:', cardFilter);
      const collection = state.collections[state.activeCollection].content;
      const relevantCards = cardFilter 
        ? Object.entries(collection).filter(([cardNo]) => cardFilter.includes(cardNo))
        : Object.entries(collection);
      
      const collectedCards = relevantCards.length;
      const playsetCards = relevantCards.filter(([, card]) => card.count >= maxPerCard).length;
      
      // Ensure totalCards is never 0 to prevent division by zero
      const safeTotalCards = Math.max(totalCards, 1);
      
      return {
        completion: Math.min(Math.round((collectedCards / safeTotalCards) * 100), 100) || 0,
        playsetCompletion: Math.min(Math.round((playsetCards / safeTotalCards) * 100), 100) || 0,
        missingCards: Math.max(safeTotalCards - collectedCards, 0),
        totalCards: safeTotalCards,
        collectedCards,
        playsetCards
      };
    },

    isCardIgnored: (state) => (cardId) => {
      //console.log('isCardIgnored() called with cardId:', cardId);
      const card = state.collections[state.activeCollection].content[cardId];
      const cardData = state.collections[state.activeCollection].content[cardId];
      return toBooleanValue(cardData?.i);
    },

    isCardWanted: (state) => (cardId) => {
      //console.log('isCardWanted() called with cardId:', cardId);
      const card = state.collections[state.activeCollection].content[cardId];
      const cardData = state.collections[state.activeCollection].content[cardId];
      return toBooleanValue(cardData?.w);
    },

    getSeriesStats: (state) => (series) => {
      //console.log('getSeriesStats() called with series:', series);
      const normalizedSeries = series.toLowerCase().endsWith('-') ? 
        series.toLowerCase().slice(0, -1) : series.toLowerCase();
      return state.seriesStats[normalizedSeries];
    },

    getSeriesTradingCount: (state) => (series) => {
      //console.log('getSeriesTradingCount() called with series:', series);
      const normalizedSeries = series.toLowerCase().endsWith('-') ? 
        series.toLowerCase().slice(0, -1) : series.toLowerCase();
        
      return Object.values(state.collections.default.content)
        .filter(card => card && // Add null check
          card.cardSeries?.toLowerCase() === normalizedSeries && // Add optional chaining
          card.isTrading && 
          card.count > 0
        ).length;
    },

    getCollectionStats: (state) => () => {
      const cardlistStore = useCardlistStore();
      const globalStore = useGlobalStore();
      const seriesStats = state.collections?.seriesStats || {};
      const selectedSeries = cardlistStore.selectedSeries;

      //console.log('Current seriesStats:', seriesStats); // Debug log
      //console.log('Selected series:', selectedSeries); // Debug log

      let totalSeriesCount = 0;
      let totalCollectedCount = 0;
      let totalPlaysetCount = 0;

      // Only calculate stats for selected series
      Object.entries(seriesStats).forEach(([series, stat]) => {
        // Don't normalize the series names - use them as-is
        if (selectedSeries.some(selected => {
          // Compare exact series names
          return selected === series || 
                 // Or if seriesIncludes is true, check if one contains the other
                 (globalStore.gameConfig.seriesIncludes && 
                  (series.includes(selected) || selected.includes(series)));
        })) {
          //console.log(`Including stats for ${series}:`, stat); // Debug log
          totalSeriesCount += stat.totalSeriesCount || 0;
          totalCollectedCount += stat.totalCollectedCount || 0;
          totalPlaysetCount += stat.playsetCount || 0;
        }
      });

      const safeTotal = Math.max(totalSeriesCount, 1);
      
      // Debug logs for final calculations
      /*console.log('Final totals:', {
        totalSeriesCount,
        totalCollectedCount,
        totalPlaysetCount,
        safeTotal
      });*/

      return {
        completion: Math.min(Math.round((totalCollectedCount / safeTotal) * 100), 100),
        playsetCompletion: Math.min(Math.round((totalPlaysetCount / safeTotal) * 100), 100),
        totalCards: totalSeriesCount,
        collectedCards: totalCollectedCount,
        missingCards: Math.max(totalSeriesCount - totalCollectedCount, 0),
        playsetCount: totalPlaysetCount
      };
    },

    isCardTagged: (state) => (cardId) => {
      const cardData = state.collections[state.activeCollection].content[cardId];
      return cardData?.tag;
    },
    
    shouldShowCard: (state) => (cardId) => {
      if (state.selectedTags.length === 0) return true;
      const cardData = state.collections[state.activeCollection].content[cardId];
      return cardData?.tag && state.selectedTags.includes(cardData.tag);
    },

    getAllTags: (state) => {
      return Array.from(state.allTags).sort();
    },
  }
});
