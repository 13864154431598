import { defineStore } from 'pinia';
import { useGlobalStore } from './globalStore';

export const useAdsenseStore = defineStore('adsense', {
  state: () => ({
    adIntervalMinutes: 6,
    showFullScreenAd: false,
    showBottomAnchorAd: false,
    canClose: false,
    countdown: 3,
    lastFullScreenTime: Date.now(), // Initialize with current time
    lastBottomAnchorTime: Date.now(), // Initialize with current time
    isInitialized: false,
    initializationTime: 0,  // Add this line
    nextAdType: 'anchor', // Add this to track rotation
    lastFullScreenWithCooldown: Date.now(), // Add this new state property
    adCheckPaused: false, // Add this new state property
    isPaused: false,
    pauseStartTime: null,
    totalPausedTime: 0,
    currentAdInterval: null, // Add this to track current interval
    adCheckInterval: null,
    nextAdCheckTime: null,
  }),

  getters: {
    adIntervalMs() {
      // Use currentAdInterval if set, otherwise use default
      return (this.currentAdInterval || this.adIntervalMinutes) * 60 * 1000;
    }
  },

  actions: {
    initialize() {
      if (this.isInitialized) return;
      const globalStore = useGlobalStore();
      if (globalStore.isExUser) return;

      const now = Date.now();
      this.lastFullScreenTime = now;
      this.lastBottomAnchorTime = now;
      this.initializationTime = now;
      this.nextAdType = 'anchor';
      this.currentAdInterval = this.adIntervalMinutes; // Initialize current interval
      this.isInitialized = true;
    },

    async showFullScreen(debug = false) {
      // For debugging
      console.log('showFullScreen called with debug:', debug);
      
      // Remove the initialization check for debug mode
      if (!this.isInitialized && !debug) {
        console.log('Not initialized and not debug mode');
        return;
      }
      
      if (this.showBottomAnchorAd) {
        console.log('Bottom anchor ad is visible');
        return;
      }
      
      const globalStore = useGlobalStore();
      // Skip admin check if in debug mode
      if (!debug && globalStore.isExUser) {
        console.log('User is ex user and not in debug mode');
        return;
      }
      
      this.showFullScreenAd = true;
      this.adCheckPaused = true;
      this.canClose = false;
      this.countdown = 3;
      
      console.log('Showing fullscreen ad');
      
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(timer);
          this.canClose = true;
        }
      }, 1000);
    },

    closeFullScreen() {
      if (!this.canClose) return;
      this.showFullScreenAd = false;
      this.adCheckPaused = false; // Resume ad rotation
    },

    showBottomAnchor(debug = false) {
      if (!this.isInitialized && !debug) return;
      if (this.showFullScreenAd) return; // Don't show if another ad is visible
      const globalStore = useGlobalStore();
      if (!debug && globalStore.isExUser) return;

      // Only check cooldown for manual ad displays
      if (debug && this.showBottomAnchorAd) return;

      this.showBottomAnchorAd = true;
      this.adCheckPaused = true; // Pause ad rotation
    },

    closeBottomAnchor() {
      this.showBottomAnchorAd = false;
      this.adCheckPaused = false; // Resume ad rotation
    },

    isOnCooldown() {
      const now = Date.now();
      const timeSinceLastAd = now - this.lastFullScreenWithCooldown;
      return timeSinceLastAd < 80000; // 30 seconds in milliseconds
    },

    async showFullScreenWithCooldown() {
      if (this.isOnCooldown()) {
        return false;
      }

      const globalStore = useGlobalStore();
      if (globalStore.isExUser) {
        return false;
      }

      // Update both cooldown timers
      const now = Date.now();
      this.lastFullScreenWithCooldown = now;
      this.lastFullScreenTime = now;
      
      await this.showFullScreen(true); // Changed this line to show fullscreen ad with debug flag
      return true;
    },

    pauseAdTimer() {
      if (!this.isPaused) {
        this.isPaused = true;
        this.pauseStartTime = Date.now();
        this.adCheckPaused = true;
      }
    },

    resumeAdTimer() {
      if (this.isPaused) {
        this.totalPausedTime += Date.now() - this.pauseStartTime;
        this.isPaused = false;
        this.pauseStartTime = null;
        this.adCheckPaused = false;
      }
    },

    getAdjustedTimestamp() {
      return Date.now() - this.totalPausedTime;
    },

    reduceNextAdTimer() {
      if (!this.nextAdCheckTime) return;
      
      const now = Date.now();
      const remainingTime = this.nextAdCheckTime - now;
      this.nextAdCheckTime = now + (remainingTime / 2);
      
      if (this.currentAdInterval) {
        this.currentAdInterval = Math.max(1, this.currentAdInterval / 2);
      }
      
      console.log('Ad timer reduced, next ad in:', Math.round((this.nextAdCheckTime - now)/1000), 'seconds');
    },

    startAdCheck(checkFunction) {
      this.clearAdCheck();
      this.nextAdCheckTime = Date.now() + this.adIntervalMs;
      this.adCheckInterval = setInterval(() => {
        if (!this.adCheckPaused && Date.now() >= this.nextAdCheckTime) {
          checkFunction();
          this.nextAdCheckTime = Date.now() + this.adIntervalMs;
        }
      }, 1000);
    },

    clearAdCheck() {
      if (this.adCheckInterval) {
        clearInterval(this.adCheckInterval);
        this.adCheckInterval = null;
      }
    }
  }
});
